import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const browser = typeof window !== 'undefined' && window
// console.log(1, {browser})

// if(typeof window !== "undefined")
//   console.log(2, typeof window, window)

export default () => {
  // console.log(3, {browser})

  // if(typeof window !== "undefined")
  //   console.log(4, typeof window, window)

  return (
    browser && (
      <>
        <Seo title='404 - Page Not Found' />
        <Divider />
        <Stack>
          <Main>
            <Section>
              <PageTitle
                header="Sorry, this page isn't available."
                subheader='You may have mistyped the address or the page may have moved.'
              />
            </Section>
            <Section>
              <Button variant='primary' as={Link} to='/'>
                Go to homepage
              </Button>
            </Section>
          </Main>
        </Stack>
      </>
    )
  )
}
